import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.services,"item-key":"serviceId","sort-by":"name","disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){$event.stopPropagation();return _vm.showItemDialog(item)}}},[_c('td',[_vm._v(_vm._s(item.serviceId))]),_c('td',[_vm._v(_vm._s(_vm.getUiVersion(item).name))]),_c('td',{staticStyle:{"text-align":"center"}},[_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(item.centerService),expression:"item.centerService"}]},[_vm._v(" mdi-check ")])],1),_c('td',_vm._l((item.lgVersions),function(lgVersion){return _c('country-flag',{key:lgVersion.lgCode,attrs:{"country":_vm.countryCodeForLgCode(lgVersion.lgCode),"size":"small"}})}),1),_c('td',[_vm._v(_vm._s(_vm.formatDateTime(item.lastUpdated)))]),_c('td',[_c(VIcon,{attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_vm._v(" mdi-delete-forever ")])],1)])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c('DialogEdit',{attrs:{"title":"Opprett tjeneste/fasilitet","create-model":_vm.createNewModel,"save":_vm.save,"support-languages":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v(" mdi-playlist-plus ")])]}},{key:"edit",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataServiceEdit',{attrs:{"model":model,"language":language,"create-lg-version":_vm.createLgVersion}})]}}])})],1),_c('DialogEdit',{ref:"itemDialog",attrs:{"title":"Tjeneste","edit-title":"Rediger tjeneste","save":_vm.save,"support-languages":true},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataServiceView',{attrs:{"model":model,"language":language}})]}},{key:"edit",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataServiceEdit',{attrs:{"model":model,"language":language,"create-lg-version":_vm.createLgVersion}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }