import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"grid-list-md":""}},[(_vm.model)?_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VTextField,{attrs:{"autofocus":"","label":"Navn*","maxlength":"50","rules":[_vm.rules.required]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('country-flag',{attrs:{"country":_vm.language.flagCountryCode,"size":"small"}})]},proxy:true}],null,false,2910774066),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VTextField,{attrs:{"label":"Bilde IVID","rules":[_vm.rules.positiveInteger]},model:{value:(_vm.imageVaultId),callback:function ($$v) {_vm.imageVaultId=$$v},expression:"imageVaultId"}})],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VCheckbox,{attrs:{"label":"Sentertjeneste"},model:{value:(_vm.centerService),callback:function ($$v) {_vm.centerService=$$v},expression:"centerService"}})],1),_c(VFlex,{attrs:{"xs12":""}},[(_vm.imageUrl)?_c(VImg,{attrs:{"src":_vm.imageUrl}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }